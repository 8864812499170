/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddBookingIntentCommand } from '../models';
// @ts-ignore
import { AddBookingIntentCommandResult } from '../models';
// @ts-ignore
import { AddCancellationProtectionCommand } from '../models';
// @ts-ignore
import { BookingIntentCustomerInfoUpdateCommand } from '../models';
// @ts-ignore
import { BookingIntentCustomerInfoUpdateCommandResult } from '../models';
// @ts-ignore
import { BookingIntentRenterMissingFieldsQueryResult } from '../models';
// @ts-ignore
import { BookingIntentUpdateRenterCommand } from '../models';
// @ts-ignore
import { BookingIntentUpdateRenterCommandResult } from '../models';
// @ts-ignore
import { BookingItemQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingStatusQueryResult } from '../models';
// @ts-ignore
import { CalculateFwcBookingAddonsQuery } from '../models';
// @ts-ignore
import { CalculateFwcBookingAddonsQueryResult } from '../models';
// @ts-ignore
import { ChangeBookingIntentRenterDetailsCommand } from '../models';
// @ts-ignore
import { CreateOpenInquiryCommand } from '../models';
// @ts-ignore
import { CustomerBookingIntentQueryResult } from '../models';
// @ts-ignore
import { CustomerBookingOfferQueryResult } from '../models';
// @ts-ignore
import { CustomerBookingRequestQueryResult } from '../models';
// @ts-ignore
import { CustomerBookingReservationQueryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateBookingAddonsCommand } from '../models';
// @ts-ignore
import { UpdateBookingBillingInformationCommand } from '../models';
// @ts-ignore
import { UpdateFwcBookingAddonsCommand } from '../models';
// @ts-ignore
import { WholeAmount } from '../models';
/**
 * BookingMarketplaceApi - axios parameter creator
 * @export
 */
export const BookingMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the HTML template of the insurance contract.
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet: async (guid: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet', 'guid', guid)
            const localVarPath = `/api/v1/marketplace/bookings/{guid}/car-insurance/contract/html`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set\'s booking\'s addons
         * @param {string} id The booking reference code identifier.
         * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdAddonsPut: async (id: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdAddonsPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingAddonsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the booking billing information for the booking (all states)
         * @param {string} id The booking reference code identifier.
         * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdBillingInformationPut: async (id: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdBillingInformationPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/billing-information`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingBillingInformationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate expected amount when specific bookings are
         * @param {string} id The booking reference code.
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdCalculateAddonsPricePost: async (id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdCalculateAddonsPricePost', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/calculate-addons-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateFwcBookingAddonsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set\'s booking\'s addons
         * @param {string} id The booking reference code identifier.
         * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdFwcAddonsPut: async (id: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdFwcAddonsPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/fwc-addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFwcBookingAddonsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdNotifyManagerPaymentGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdNotifyManagerPaymentGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/notify-manager-payment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/notify-manager-payment-v2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdPromoCodeDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdPromoCodeDelete', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/promo-code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [promoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdPromoCodePut: async (id: string, promoCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdPromoCodePut', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/promo-code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promoCode !== undefined) {
                localVarQueryParameter['promoCode'] = promoCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdStateGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdStateGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdVariableSymbolPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdVariableSymbolPost', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/variable-symbol`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdVariableSymbolPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsIdVariableSymbolPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/bookings/{id}/variable-symbol`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the empty booking intent.  Sends out mailer to Campiri and customer.
         * @param {string} [captcha] 
         * @param {CreateOpenInquiryCommand} [createOpenInquiryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsOpenInquiryPost: async (captcha?: string, createOpenInquiryCommand?: CreateOpenInquiryCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/bookings/open-inquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (captcha !== undefined) {
                localVarQueryParameter['captcha'] = captcha;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOpenInquiryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of past bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsPastGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/bookings/past`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculates insurance price
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/bookings/{referenceCode}/cancellation-protection/calculate-price`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the cancellation protection
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/bookings/{referenceCode}/cancellation-protection`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the cancellation protection
         * @param {string} referenceCode 
         * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost: async (referenceCode: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/bookings/{referenceCode}/cancellation-protection`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCancellationProtectionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {string} referenceCode The reservation reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/bookings/{referenceCode}/car-insurance/contract/pdf`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts the cancellation protection contract.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/bookings/{referenceCode}/consents/accept-cancellation-protection-contract`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts the P2P insurance contract.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/bookings/{referenceCode}/consents/accept-p2p-insurance-contract`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of upcoming bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsUpcomingGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/bookings/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate price for addons for open checkout intent.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdCalculateAddonsPricePost: async (id: string, openCheckoutToken?: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdCalculateAddonsPricePost', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/calculate-addons-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateFwcBookingAddonsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the addons for incomplete booking intent.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut: async (id: string, openCheckoutToken?: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingAddonsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the booking billing information for the booking (all states)
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut: async (id: string, openCheckoutToken?: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/billing-information`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingBillingInformationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculates insurance price for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet: async (id: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/cancellation-protection/calculate-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the cancellation protection for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete: async (id: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/cancellation-protection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the cancellation protection for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost: async (id: string, openCheckoutToken?: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/cancellation-protection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCancellationProtectionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {string} id The reservation reference code.
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet: async (id: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/car-insurance/contract/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the fwc addons for incomplete booking intent.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut: async (id: string, openCheckoutToken?: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/fwc-addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFwcBookingAddonsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the incomplete booking intent information.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutGet: async (id: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post: async (id: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/notify-manager-payment-v2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete: async (id: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/promo-code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {string} [promoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut: async (id: string, openCheckoutToken?: string, promoCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/promo-code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }

            if (promoCode !== undefined) {
                localVarQueryParameter['promoCode'] = promoCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the booking intent information.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutPut: async (id: string, openCheckoutToken?: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeBookingIntentRenterDetailsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set booking intent renter information of logged in user.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/renter/from-session`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of missing fields for the customer.
         * @param {string} id 
         * @param {string} [completionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet: async (id: string, completionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/renter/missing-fields`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (completionToken !== undefined) {
                localVarQueryParameter['completionToken'] = completionToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set booking intent renter information.  Returns the completion code token if the account is incomplete.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {BookingIntentUpdateRenterCommand} [bookingIntentUpdateRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRenterPut: async (id: string, openCheckoutToken?: string, bookingIntentUpdateRenterCommand?: BookingIntentUpdateRenterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutRenterPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/renter`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingIntentUpdateRenterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Moves the intent to request state.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRequestPost: async (id: string, openCheckoutToken?: string, note?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdOpenCheckoutRequestPost', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/open-checkout/request`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdPut: async (id: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdPut', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeBookingIntentRenterDetailsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the incomplete user information using the completion token.
         * @param {string} id 
         * @param {string} [completionToken] 
         * @param {BookingIntentCustomerInfoUpdateCommand} [bookingIntentCustomerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdRenterCompletePatch: async (id: string, completionToken?: string, bookingIntentCustomerInfoUpdateCommand?: BookingIntentCustomerInfoUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdRenterCompletePatch', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/renter/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (completionToken !== undefined) {
                localVarQueryParameter['completionToken'] = completionToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingIntentCustomerInfoUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Moves the intent to request state.
         * @param {string} id 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdRequestPost: async (id: string, note?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceIntentsIdRequestPost', 'id', id)
            const localVarPath = `/api/v1/marketplace/intents/{id}/request`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new booking intent - renter does not need to be logged in.  First step of creating the booking.
         * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsOpenCheckoutPost: async (addBookingIntentCommand?: AddBookingIntentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/intents/open-checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBookingIntentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new booking intent.  First step of creating the booking.
         * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsPost: async (addBookingIntentCommand?: AddBookingIntentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBookingIntentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceOffersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceOffersIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Denies a booking offer
         * @param {string} referenceCode The booking reference code identifier.
         * @param {string} [reason] The deny reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceOffersReferenceCodeDenyPost: async (referenceCode: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceOffersReferenceCodeDenyPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/offers/{referenceCode}/deny`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceRequestsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceRequestsIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels the booking request.
         * @param {string} id The booking reference code identifier.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceRequestsIdWithdrawPost: async (id: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceRequestsIdWithdrawPost', 'id', id)
            const localVarPath = `/api/v1/marketplace/requests/{id}/withdraw`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a insurance uploaded document.
         * @param {string} id The reservation reference code.
         * @param {string} guid The document identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdDocumentsGuidGet: async (id: string, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsIdDocumentsGuidGet', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsIdDocumentsGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/marketplace/reservations/{id}/documents/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/reservations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingMarketplaceApi - functional programming interface
 * @export
 */
export const BookingMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the HTML template of the insurance contract.
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet(guid, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set\'s booking\'s addons
         * @param {string} id The booking reference code identifier.
         * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdAddonsPut(id: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdAddonsPut(id, updateBookingAddonsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the booking billing information for the booking (all states)
         * @param {string} id The booking reference code identifier.
         * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdBillingInformationPut(id: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdBillingInformationPut(id, updateBookingBillingInformationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate expected amount when specific bookings are
         * @param {string} id The booking reference code.
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateFwcBookingAddonsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id, calculateFwcBookingAddonsQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set\'s booking\'s addons
         * @param {string} id The booking reference code identifier.
         * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdFwcAddonsPut(id: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdFwcAddonsPut(id, updateFwcBookingAddonsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdNotifyManagerPaymentGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdNotifyManagerPaymentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdPromoCodeDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdPromoCodeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [promoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdPromoCodePut(id: string, promoCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdPromoCodePut(id, promoCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdStateGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingStatusQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdStateGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdVariableSymbolPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdVariableSymbolPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsIdVariableSymbolPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsIdVariableSymbolPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the empty booking intent.  Sends out mailer to Campiri and customer.
         * @param {string} [captcha] 
         * @param {CreateOpenInquiryCommand} [createOpenInquiryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsOpenInquiryPost(captcha?: string, createOpenInquiryCommand?: CreateOpenInquiryCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsOpenInquiryPost(captcha, createOpenInquiryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of past bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsPastGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingItemQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsPastGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculates insurance price
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WholeAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the cancellation protection
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the cancellation protection
         * @param {string} referenceCode 
         * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(referenceCode: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(referenceCode, addCancellationProtectionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {string} referenceCode The reservation reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accepts the cancellation protection contract.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accepts the P2P insurance contract.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of upcoming bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBookingsUpcomingGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingItemQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBookingsUpcomingGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate price for addons for open checkout intent.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id: string, openCheckoutToken?: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateFwcBookingAddonsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id, openCheckoutToken, calculateFwcBookingAddonsQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBookingIntentQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the addons for incomplete booking intent.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(id: string, openCheckoutToken?: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(id, openCheckoutToken, updateBookingAddonsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the booking billing information for the booking (all states)
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(id: string, openCheckoutToken?: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(id, openCheckoutToken, updateBookingBillingInformationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculates insurance price for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WholeAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(id, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the cancellation protection for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(id, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the cancellation protection for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(id: string, openCheckoutToken?: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(id, openCheckoutToken, addCancellationProtectionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {string} id The reservation reference code.
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(id, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the fwc addons for incomplete booking intent.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id: string, openCheckoutToken?: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id, openCheckoutToken, updateFwcBookingAddonsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the incomplete booking intent information.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBookingIntentQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutGet(id, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {string} [promoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id: string, openCheckoutToken?: string, promoCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id, openCheckoutToken, promoCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the booking intent information.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutPut(id: string, openCheckoutToken?: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutPut(id, openCheckoutToken, changeBookingIntentRenterDetailsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set booking intent renter information of logged in user.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of missing fields for the customer.
         * @param {string} id 
         * @param {string} [completionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id: string, completionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingIntentRenterMissingFieldsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id, completionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set booking intent renter information.  Returns the completion code token if the account is incomplete.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {BookingIntentUpdateRenterCommand} [bookingIntentUpdateRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id: string, openCheckoutToken?: string, bookingIntentUpdateRenterCommand?: BookingIntentUpdateRenterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingIntentUpdateRenterCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id, openCheckoutToken, bookingIntentUpdateRenterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Moves the intent to request state.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id: string, openCheckoutToken?: string, note?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id, openCheckoutToken, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdPut(id: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdPut(id, changeBookingIntentRenterDetailsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the incomplete user information using the completion token.
         * @param {string} id 
         * @param {string} [completionToken] 
         * @param {BookingIntentCustomerInfoUpdateCommand} [bookingIntentCustomerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdRenterCompletePatch(id: string, completionToken?: string, bookingIntentCustomerInfoUpdateCommand?: BookingIntentCustomerInfoUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingIntentCustomerInfoUpdateCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdRenterCompletePatch(id, completionToken, bookingIntentCustomerInfoUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Moves the intent to request state.
         * @param {string} id 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsIdRequestPost(id: string, note?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsIdRequestPost(id, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new booking intent - renter does not need to be logged in.  First step of creating the booking.
         * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddBookingIntentCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new booking intent.  First step of creating the booking.
         * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceIntentsPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddBookingIntentCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceIntentsPost(addBookingIntentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceOffersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBookingOfferQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceOffersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Denies a booking offer
         * @param {string} referenceCode The booking reference code identifier.
         * @param {string} [reason] The deny reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceOffersReferenceCodeDenyPost(referenceCode: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceOffersReferenceCodeDenyPost(referenceCode, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceRequestsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBookingRequestQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels the booking request.
         * @param {string} id The booking reference code identifier.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceRequestsIdWithdrawPost(id: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceRequestsIdWithdrawPost(id, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a insurance uploaded document.
         * @param {string} id The reservation reference code.
         * @param {string} guid The document identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsIdDocumentsGuidGet(id: string, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsIdDocumentsGuidGet(id, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBookingReservationQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingMarketplaceApi - factory interface
 * @export
 */
export const BookingMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the HTML template of the insurance contract.
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet(guid: string, accessToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet(guid, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set\'s booking\'s addons
         * @param {string} id The booking reference code identifier.
         * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdAddonsPut(id: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsIdAddonsPut(id, updateBookingAddonsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the booking billing information for the booking (all states)
         * @param {string} id The booking reference code identifier.
         * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdBillingInformationPut(id: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsIdBillingInformationPut(id, updateBookingBillingInformationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate expected amount when specific bookings are
         * @param {string} id The booking reference code.
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: any): AxiosPromise<CalculateFwcBookingAddonsQueryResult> {
            return localVarFp.apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id, calculateFwcBookingAddonsQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set\'s booking\'s addons
         * @param {string} id The booking reference code identifier.
         * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdFwcAddonsPut(id: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsIdFwcAddonsPut(id, updateFwcBookingAddonsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdNotifyManagerPaymentGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsIdNotifyManagerPaymentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdPromoCodeDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1MarketplaceBookingsIdPromoCodeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [promoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdPromoCodePut(id: string, promoCode?: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1MarketplaceBookingsIdPromoCodePut(id, promoCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdStateGet(id: string, options?: any): AxiosPromise<BookingStatusQueryResult> {
            return localVarFp.apiV1MarketplaceBookingsIdStateGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdVariableSymbolPost(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1MarketplaceBookingsIdVariableSymbolPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsIdVariableSymbolPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsIdVariableSymbolPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the empty booking intent.  Sends out mailer to Campiri and customer.
         * @param {string} [captcha] 
         * @param {CreateOpenInquiryCommand} [createOpenInquiryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsOpenInquiryPost(captcha?: string, createOpenInquiryCommand?: CreateOpenInquiryCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsOpenInquiryPost(captcha, createOpenInquiryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of past bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsPastGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<BookingItemQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceBookingsPastGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculates insurance price
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode: string, options?: any): AxiosPromise<WholeAmount> {
            return localVarFp.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the cancellation protection
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the cancellation protection
         * @param {string} referenceCode 
         * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(referenceCode: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(referenceCode, addCancellationProtectionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {string} referenceCode The reservation reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts the cancellation protection contract.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost(referenceCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts the P2P insurance contract.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost(referenceCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of upcoming bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBookingsUpcomingGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<BookingItemQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceBookingsUpcomingGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate price for addons for open checkout intent.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id: string, openCheckoutToken?: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: any): AxiosPromise<CalculateFwcBookingAddonsQueryResult> {
            return localVarFp.apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id, openCheckoutToken, calculateFwcBookingAddonsQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdGet(id: string, options?: any): AxiosPromise<CustomerBookingIntentQueryResult> {
            return localVarFp.apiV1MarketplaceIntentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the addons for incomplete booking intent.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(id: string, openCheckoutToken?: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(id, openCheckoutToken, updateBookingAddonsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the booking billing information for the booking (all states)
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(id: string, openCheckoutToken?: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(id, openCheckoutToken, updateBookingBillingInformationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculates insurance price for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(id: string, openCheckoutToken?: string, options?: any): AxiosPromise<WholeAmount> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(id, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the cancellation protection for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(id: string, openCheckoutToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(id, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the cancellation protection for incomplete intent booking.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(id: string, openCheckoutToken?: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(id, openCheckoutToken, addCancellationProtectionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {string} id The reservation reference code.
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(id: string, openCheckoutToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(id, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the fwc addons for incomplete booking intent.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id: string, openCheckoutToken?: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id, openCheckoutToken, updateFwcBookingAddonsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the incomplete booking intent information.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutGet(id: string, openCheckoutToken?: string, options?: any): AxiosPromise<CustomerBookingIntentQueryResult> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutGet(id, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id: string, openCheckoutToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id: string, openCheckoutToken?: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates the variable symbol for the booking.
         * @param {string} id The booking reference code.
         * @param {string} [openCheckoutToken] 
         * @param {string} [promoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id: string, openCheckoutToken?: string, promoCode?: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id, openCheckoutToken, promoCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the booking intent information.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutPut(id: string, openCheckoutToken?: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutPut(id, openCheckoutToken, changeBookingIntentRenterDetailsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set booking intent renter information of logged in user.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of missing fields for the customer.
         * @param {string} id 
         * @param {string} [completionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id: string, completionToken?: string, options?: any): AxiosPromise<BookingIntentRenterMissingFieldsQueryResult> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id, completionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set booking intent renter information.  Returns the completion code token if the account is incomplete.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {BookingIntentUpdateRenterCommand} [bookingIntentUpdateRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id: string, openCheckoutToken?: string, bookingIntentUpdateRenterCommand?: BookingIntentUpdateRenterCommand, options?: any): AxiosPromise<BookingIntentUpdateRenterCommandResult> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id, openCheckoutToken, bookingIntentUpdateRenterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Moves the intent to request state.
         * @param {string} id 
         * @param {string} [openCheckoutToken] 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id: string, openCheckoutToken?: string, note?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id, openCheckoutToken, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdPut(id: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdPut(id, changeBookingIntentRenterDetailsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the incomplete user information using the completion token.
         * @param {string} id 
         * @param {string} [completionToken] 
         * @param {BookingIntentCustomerInfoUpdateCommand} [bookingIntentCustomerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdRenterCompletePatch(id: string, completionToken?: string, bookingIntentCustomerInfoUpdateCommand?: BookingIntentCustomerInfoUpdateCommand, options?: any): AxiosPromise<BookingIntentCustomerInfoUpdateCommandResult> {
            return localVarFp.apiV1MarketplaceIntentsIdRenterCompletePatch(id, completionToken, bookingIntentCustomerInfoUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Moves the intent to request state.
         * @param {string} id 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsIdRequestPost(id: string, note?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceIntentsIdRequestPost(id, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new booking intent - renter does not need to be logged in.  First step of creating the booking.
         * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: any): AxiosPromise<AddBookingIntentCommandResult> {
            return localVarFp.apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new booking intent.  First step of creating the booking.
         * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceIntentsPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: any): AxiosPromise<AddBookingIntentCommandResult> {
            return localVarFp.apiV1MarketplaceIntentsPost(addBookingIntentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceOffersIdGet(id: string, options?: any): AxiosPromise<CustomerBookingOfferQueryResult> {
            return localVarFp.apiV1MarketplaceOffersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Denies a booking offer
         * @param {string} referenceCode The booking reference code identifier.
         * @param {string} [reason] The deny reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceOffersReferenceCodeDenyPost(referenceCode: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceOffersReferenceCodeDenyPost(referenceCode, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceRequestsIdGet(id: string, options?: any): AxiosPromise<CustomerBookingRequestQueryResult> {
            return localVarFp.apiV1MarketplaceRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels the booking request.
         * @param {string} id The booking reference code identifier.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceRequestsIdWithdrawPost(id: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceRequestsIdWithdrawPost(id, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a insurance uploaded document.
         * @param {string} id The reservation reference code.
         * @param {string} guid The document identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdDocumentsGuidGet(id: string, guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceReservationsIdDocumentsGuidGet(id, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdGet(id: string, options?: any): AxiosPromise<CustomerBookingReservationQueryResult> {
            return localVarFp.apiV1MarketplaceReservationsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingMarketplaceApi - interface
 * @export
 * @interface BookingMarketplaceApi
 */
export interface BookingMarketplaceApiInterface {
    /**
     * 
     * @summary Gets the HTML template of the insurance contract.
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Set\'s booking\'s addons
     * @param {string} id The booking reference code identifier.
     * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdAddonsPut(id: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Changes the booking billing information for the booking (all states)
     * @param {string} id The booking reference code identifier.
     * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdBillingInformationPut(id: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Calculate expected amount when specific bookings are
     * @param {string} id The booking reference code.
     * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig): AxiosPromise<CalculateFwcBookingAddonsQueryResult>;

    /**
     * 
     * @summary Set\'s booking\'s addons
     * @param {string} id The booking reference code identifier.
     * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdFwcAddonsPut(id: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdNotifyManagerPaymentGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdPromoCodeDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {string} [promoCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdPromoCodePut(id: string, promoCode?: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Gets the booking status information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdStateGet(id: string, options?: AxiosRequestConfig): AxiosPromise<BookingStatusQueryResult>;

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdVariableSymbolPost(id: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsIdVariableSymbolPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates the empty booking intent.  Sends out mailer to Campiri and customer.
     * @param {string} [captcha] 
     * @param {CreateOpenInquiryCommand} [createOpenInquiryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsOpenInquiryPost(captcha?: string, createOpenInquiryCommand?: CreateOpenInquiryCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a list of past bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsPastGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<BookingItemQueryResultPaginatedResult>;

    /**
     * 
     * @summary Calculates insurance price
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<WholeAmount>;

    /**
     * 
     * @summary Removes the cancellation protection
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates the cancellation protection
     * @param {string} referenceCode 
     * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(referenceCode: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the PDF file of mediation contract.
     * @param {string} referenceCode The reservation reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Accepts the cancellation protection contract.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Accepts the P2P insurance contract.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a list of upcoming bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceBookingsUpcomingGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<BookingItemQueryResultPaginatedResult>;

    /**
     * 
     * @summary Calculate price for addons for open checkout intent.
     * @param {string} id The booking reference code.
     * @param {string} [openCheckoutToken] 
     * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id: string, openCheckoutToken?: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig): AxiosPromise<CalculateFwcBookingAddonsQueryResult>;

    /**
     * 
     * @summary Gets the booking intent information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<CustomerBookingIntentQueryResult>;

    /**
     * 
     * @summary Sets the addons for incomplete booking intent.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(id: string, openCheckoutToken?: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Changes the booking billing information for the booking (all states)
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(id: string, openCheckoutToken?: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Calculates insurance price for incomplete intent booking.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<WholeAmount>;

    /**
     * 
     * @summary Removes the cancellation protection for incomplete intent booking.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates the cancellation protection for incomplete intent booking.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(id: string, openCheckoutToken?: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the PDF file of mediation contract.
     * @param {string} id The reservation reference code.
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets the fwc addons for incomplete booking intent.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id: string, openCheckoutToken?: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the incomplete booking intent information.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<CustomerBookingIntentQueryResult>;

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {string} [openCheckoutToken] 
     * @param {string} [promoCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id: string, openCheckoutToken?: string, promoCode?: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Updates the booking intent information.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutPut(id: string, openCheckoutToken?: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Set booking intent renter information of logged in user.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets list of missing fields for the customer.
     * @param {string} id 
     * @param {string} [completionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id: string, completionToken?: string, options?: AxiosRequestConfig): AxiosPromise<BookingIntentRenterMissingFieldsQueryResult>;

    /**
     * 
     * @summary Set booking intent renter information.  Returns the completion code token if the account is incomplete.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {BookingIntentUpdateRenterCommand} [bookingIntentUpdateRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id: string, openCheckoutToken?: string, bookingIntentUpdateRenterCommand?: BookingIntentUpdateRenterCommand, options?: AxiosRequestConfig): AxiosPromise<BookingIntentUpdateRenterCommandResult>;

    /**
     * 
     * @summary Moves the intent to request state.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {string} [note] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id: string, openCheckoutToken?: string, note?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the booking intent information.
     * @param {string} id The booking reference code identifier.
     * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdPut(id: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the incomplete user information using the completion token.
     * @param {string} id 
     * @param {string} [completionToken] 
     * @param {BookingIntentCustomerInfoUpdateCommand} [bookingIntentCustomerInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdRenterCompletePatch(id: string, completionToken?: string, bookingIntentCustomerInfoUpdateCommand?: BookingIntentCustomerInfoUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<BookingIntentCustomerInfoUpdateCommandResult>;

    /**
     * 
     * @summary Moves the intent to request state.
     * @param {string} id 
     * @param {string} [note] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsIdRequestPost(id: string, note?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new booking intent - renter does not need to be logged in.  First step of creating the booking.
     * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: AxiosRequestConfig): AxiosPromise<AddBookingIntentCommandResult>;

    /**
     * 
     * @summary Creates a new booking intent.  First step of creating the booking.
     * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceIntentsPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: AxiosRequestConfig): AxiosPromise<AddBookingIntentCommandResult>;

    /**
     * 
     * @summary Gets the booking offer information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceOffersIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<CustomerBookingOfferQueryResult>;

    /**
     * 
     * @summary Denies a booking offer
     * @param {string} referenceCode The booking reference code identifier.
     * @param {string} [reason] The deny reason.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceOffersReferenceCodeDenyPost(referenceCode: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking request information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceRequestsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<CustomerBookingRequestQueryResult>;

    /**
     * 
     * @summary Cancels the booking request.
     * @param {string} id The booking reference code identifier.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceRequestsIdWithdrawPost(id: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a insurance uploaded document.
     * @param {string} id The reservation reference code.
     * @param {string} guid The document identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsIdDocumentsGuidGet(id: string, guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<CustomerBookingReservationQueryResult>;

}

/**
 * BookingMarketplaceApi - object-oriented interface
 * @export
 * @class BookingMarketplaceApi
 * @extends {BaseAPI}
 */
export class BookingMarketplaceApi extends BaseAPI implements BookingMarketplaceApiInterface {
    /**
     * 
     * @summary Gets the HTML template of the insurance contract.
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsGuidCarInsuranceContractHtmlGet(guid, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set\'s booking\'s addons
     * @param {string} id The booking reference code identifier.
     * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdAddonsPut(id: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdAddonsPut(id, updateBookingAddonsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the booking billing information for the booking (all states)
     * @param {string} id The booking reference code identifier.
     * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdBillingInformationPut(id: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdBillingInformationPut(id, updateBookingBillingInformationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate expected amount when specific bookings are
     * @param {string} id The booking reference code.
     * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id, calculateFwcBookingAddonsQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set\'s booking\'s addons
     * @param {string} id The booking reference code identifier.
     * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdFwcAddonsPut(id: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdFwcAddonsPut(id, updateFwcBookingAddonsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdNotifyManagerPaymentGet(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdNotifyManagerPaymentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdPromoCodeDelete(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdPromoCodeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {string} [promoCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdPromoCodePut(id: string, promoCode?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdPromoCodePut(id, promoCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking status information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdStateGet(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdStateGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdVariableSymbolPost(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdVariableSymbolPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsIdVariableSymbolPut(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsIdVariableSymbolPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the empty booking intent.  Sends out mailer to Campiri and customer.
     * @param {string} [captcha] 
     * @param {CreateOpenInquiryCommand} [createOpenInquiryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsOpenInquiryPost(captcha?: string, createOpenInquiryCommand?: CreateOpenInquiryCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsOpenInquiryPost(captcha, createOpenInquiryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of past bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsPastGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsPastGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculates insurance price
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the cancellation protection
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the cancellation protection
     * @param {string} referenceCode 
     * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(referenceCode: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(referenceCode, addCancellationProtectionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the PDF file of mediation contract.
     * @param {string} referenceCode The reservation reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts the cancellation protection contract.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost(referenceCode: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsReferenceCodeConsentsAcceptCancellationProtectionContractPost(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts the P2P insurance contract.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost(referenceCode: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsReferenceCodeConsentsAcceptP2pInsuranceContractPost(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of upcoming bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceBookingsUpcomingGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceBookingsUpcomingGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate price for addons for open checkout intent.
     * @param {string} id The booking reference code.
     * @param {string} [openCheckoutToken] 
     * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id: string, openCheckoutToken?: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id, openCheckoutToken, calculateFwcBookingAddonsQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking intent information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the addons for incomplete booking intent.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {UpdateBookingAddonsCommand} [updateBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(id: string, openCheckoutToken?: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(id, openCheckoutToken, updateBookingAddonsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the booking billing information for the booking (all states)
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {UpdateBookingBillingInformationCommand} [updateBookingBillingInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(id: string, openCheckoutToken?: string, updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(id, openCheckoutToken, updateBookingBillingInformationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculates insurance price for incomplete intent booking.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(id, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the cancellation protection for incomplete intent booking.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(id, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the cancellation protection for incomplete intent booking.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {AddCancellationProtectionCommand} [addCancellationProtectionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(id: string, openCheckoutToken?: string, addCancellationProtectionCommand?: AddCancellationProtectionCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(id, openCheckoutToken, addCancellationProtectionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the PDF file of mediation contract.
     * @param {string} id The reservation reference code.
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(id, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the fwc addons for incomplete booking intent.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {UpdateFwcBookingAddonsCommand} [updateFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id: string, openCheckoutToken?: string, updateFwcBookingAddonsCommand?: UpdateFwcBookingAddonsCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id, openCheckoutToken, updateFwcBookingAddonsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the incomplete booking intent information.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutGet(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutGet(id, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends out a mailer that notifies the manager about customer wanting to pay by bank transfer.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates the variable symbol for the booking.
     * @param {string} id The booking reference code.
     * @param {string} [openCheckoutToken] 
     * @param {string} [promoCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id: string, openCheckoutToken?: string, promoCode?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id, openCheckoutToken, promoCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the booking intent information.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutPut(id: string, openCheckoutToken?: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutPut(id, openCheckoutToken, changeBookingIntentRenterDetailsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set booking intent renter information of logged in user.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of missing fields for the customer.
     * @param {string} id 
     * @param {string} [completionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id: string, completionToken?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id, completionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set booking intent renter information.  Returns the completion code token if the account is incomplete.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {BookingIntentUpdateRenterCommand} [bookingIntentUpdateRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id: string, openCheckoutToken?: string, bookingIntentUpdateRenterCommand?: BookingIntentUpdateRenterCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id, openCheckoutToken, bookingIntentUpdateRenterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Moves the intent to request state.
     * @param {string} id 
     * @param {string} [openCheckoutToken] 
     * @param {string} [note] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id: string, openCheckoutToken?: string, note?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id, openCheckoutToken, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the booking intent information.
     * @param {string} id The booking reference code identifier.
     * @param {ChangeBookingIntentRenterDetailsCommand} [changeBookingIntentRenterDetailsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdPut(id: string, changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdPut(id, changeBookingIntentRenterDetailsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the incomplete user information using the completion token.
     * @param {string} id 
     * @param {string} [completionToken] 
     * @param {BookingIntentCustomerInfoUpdateCommand} [bookingIntentCustomerInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdRenterCompletePatch(id: string, completionToken?: string, bookingIntentCustomerInfoUpdateCommand?: BookingIntentCustomerInfoUpdateCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdRenterCompletePatch(id, completionToken, bookingIntentCustomerInfoUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Moves the intent to request state.
     * @param {string} id 
     * @param {string} [note] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsIdRequestPost(id: string, note?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsIdRequestPost(id, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new booking intent - renter does not need to be logged in.  First step of creating the booking.
     * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new booking intent.  First step of creating the booking.
     * @param {AddBookingIntentCommand} [addBookingIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceIntentsPost(addBookingIntentCommand?: AddBookingIntentCommand, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceIntentsPost(addBookingIntentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking offer information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceOffersIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceOffersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Denies a booking offer
     * @param {string} referenceCode The booking reference code identifier.
     * @param {string} [reason] The deny reason.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceOffersReferenceCodeDenyPost(referenceCode: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceOffersReferenceCodeDenyPost(referenceCode, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking request information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceRequestsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels the booking request.
     * @param {string} id The booking reference code identifier.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceRequestsIdWithdrawPost(id: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceRequestsIdWithdrawPost(id, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a insurance uploaded document.
     * @param {string} id The reservation reference code.
     * @param {string} guid The document identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceReservationsIdDocumentsGuidGet(id: string, guid: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsIdDocumentsGuidGet(id, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingMarketplaceApi
     */
    public apiV1MarketplaceReservationsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}

